globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"5_NkclAeD0Kr5dgZuTuI3"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
import * as Sentry from '@sentry/nextjs';
import config from './config';

Sentry.init({
	dsn: config.SENTRY_DSN,
	tracesSampleRate: 0.05,
	environment: config.ENV_NAME,
	beforeSend(event) {
		if (event.exception && event.exception.values) {
			const exception = event.exception.values[0];
			const errorValues = event.exception.values;
			if (exception?.stacktrace && exception.stacktrace.frames) {
				if (
					exception.stacktrace.frames.some(frame =>
						frame.filename?.includes('phyron2.js'),
					)
				) {
					return null;
				}
			}

			// Happens when a GraphQL request fails due to missing/expired authentication.
			// Ignored because it's a common expected behavior.
			if (
				errorValues.some(
					error =>
						error.type?.includes('ApolloError') &&
						(error.value?.includes(
							'You must be logged as customer',
						) ||
							error.value?.includes('No customerId in context')),
				)
			) {
				return null;
			}

			// Occurs when an infinite loop of resize events is detected.
			// Mostly a harmless browser warning.
			if (
				errorValues.some(error =>
					error.value?.includes(
						'ResizeObserver loop completed with undelivered notifications',
					),
				)
			) {
				return null;
			}

			// Happens when a network request fails (server down, CORS issue, no internet).
			// Ignored because it's often temporary and not a real bug.
			if (
				errorValues.some(
					error =>
						error.value?.includes('Failed to fetch') ||
						error.value?.includes('Load failed'),
				)
			) {
				return null;
			}
		}

		return event;
	},
});
